/** @jsxImportSource @emotion/react */
import {
  Suspense,
  lazy,
  Fragment,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Loader from '../../../../shared/components/molecules/loader';
import getRoutesV1 from './v1';
import {
  CONVERSATIONS_PAGE_VALID_SOCKET_NAMES,
  DEFAULT_MEMBER,
  LOADING,
} from '../../../../shared/constants';
import { IMPORT_EXCEL_STORAGE_KEYS } from '../../../../shared/constants/storage';
import { Header } from '../../../../shared/components/organisms/';
import { css } from '@emotion/react';
import { ROUTES } from '../../../../shared/constants/routes';
import {
  CONNECTION_LOST_STATUS_BAR_HEIGHT,
  HEADER_HEIGHT,
  SIDEBAR_WIDTH,
} from '../../../../shared/typings';
import { useAtomValue, useSetAtom } from 'jotai';
import useMiniListeners from '../../hooks/useMiniListeners';
import { allUserPermissions, rootScope } from '../../../../shared/states/user';
import Sidebar from '../../components/sidebar';
import { CreateOwnAccountModalRenderer } from '../../../../shared/modules/demo-account/v1/components';
import Dialog360Callback from '../../components/dialog360';
import { ProtectedRoute } from './v1/protected-route';
import RechargeModal from '../../../../shared/components/molecules/sidebar-wallet/recharge-modal';
import BotBuilderModal from '../../../crm-mini/bots/v1/bot';
import { importExcelFilesStatusAtom } from '../../../customers/state';
import { getExcelSheetsStatus } from '../../../customers/requests';
import { OperationStatus } from '../../../home/v1/typings';
import useImportExcelSocket from '../../../home/v1/hooks/use-import-excel-socket';
import useNotifications from '../../../../shared/hooks/use-notifications';
import useAgentStatusSocket from '../../../home/v1/hooks/use-agent-status-socket';
import UpgradePlanModal from '../../../../shared/components/molecules/upgrade-plan-modal';
import NegativeWalletBalanceModal from '../../../../shared/components/molecules/sidebar-wallet/negative-balance-blocker';
import { walletDetailsAtom } from '../../../../shared/states/wallet';
import ValueFirstUpgradeModalInformation from '../../../value-first/components/value-first-upgrade-information';
import { showValueFirstUpgradeModalAtom } from '../../../home/v1/states/value-first-modal';
import { wabaIntegrationsAtomFamily } from '../../../../shared/states/waba';
import {
  WabaChannelStatus,
  WabaIntegration,
  WabaIntegrationPaginationParams,
  WabaIntegrationType,
} from '../../../../shared/typings/waba';
import usePaginatedQuery from '../../../../shared/hooks/usePaginatedQuery';
import { getWabaIntegrations } from '../../../../shared/requests/waba';
import PushNotificationPermissionPopup from '../../../settings/v1/components/pushNotifications/PushNotificationPermissionPopup';
import usePlayNotificationSound from '../../../../shared/hooks/usePlayNotificationSound';
import { enterpriseAnalyticsDetailsAtom } from '../../../../shared/states/enterprise-analytics';
import { useConversationSocket } from '../../../home/v1/hooks/use-conversation-socket';
import ConnectionLostBar from '../../../../shared/components/organisms/connection-lost-bar';
import { socketConnectionAtom } from '../../../../shared/states/socket-connection';
import { selectedChatAtom } from '../../../home/v1/states/selected-chat';
import { SocketName } from '../../../../shared/typings/socket-connection';
import useNetworkStatus from '../../../../shared/hooks/use-network-status';
import { isEmbedAtom } from '../../../embed/state';

const PostLoginComponent = lazy(() => import('./post-login'));

const SignupLazyComponent = lazy(
  () => import('../../../login/v1/components/signup')
);
const ShopifySignupLazyComponent = lazy(
  () => import('../../../shopify/v1/components/shopify-signup')
);
const SignInLazyComponent = lazy(
  () => import('../../../login/v1/components/signin')
);
const LoginLazyComponent = lazy(
  () => import('../../../login/v1/components/login')
);
const EmbedAutoLoginLazyComponent = lazy(
  () => import('../../../login/v1/components/organisms/EmbedAutoLogin')
);
const ContactSupportLazyComponent = lazy(
  () => import('../../../contact-support/components/index')
);
const BillingDetailsLazyComponent = lazy(
  () => import('../../../login/v1/components/billing-details-page')
);
const CompanyDetailsLazyComponent = lazy(
  () => import('../../../login/v1/components/company-details-page')
);
const EmailVerificationLazyComponent = lazy(
  () => import('../../../login/v1/components/email-verification-page')
);
const NextStepsLazyComponent = lazy(
  () => import('../../../login/v1/components/next-step')
);
const AutoLoginLazyComponent = lazy(
  () => import('../../../login/v1/components/auto-login')
);

const RenewPlanLazyComponent = lazy(
  () => import('../../../renew-plan/v1/components')
);

const PaymentScreenMiniLazyComponent = lazy(
  () => import('../../../renew-plan/v1/components/payment-screen-mini')
);

const EmbedLazyComponent = lazy(() => import('../../../embed'));

export function AppProtectedRoutes() {
  const userPermissions = useAtomValue(allUserPermissions, rootScope);
  const {
    loading: enterpriseAnalyticsDetailsLoading,
    data: enterpriseAnalyticsDetails,
  } = useAtomValue(enterpriseAnalyticsDetailsAtom);
  usePlayNotificationSound();
  const setImportExcelFilesStatus = useSetAtom(importExcelFilesStatusAtom);
  const { data: walletDetails, loading: walletDetailsLoading } =
    useAtomValue(walletDetailsAtom);
  const { addNotification } = useNotifications();
  const isWalletBalanceNegative = useMemo(() => {
    if (!walletDetails || walletDetailsLoading) return false;
    switch (walletDetails.currencyCode) {
      case 'INR':
        return walletDetails.balance < -1000;
      case 'USD':
        return walletDetails.balance < -10;
      case 'AED':
        return walletDetails.balance < -25;
      default:
        return walletDetails.balance < -10;
    }
  }, [walletDetails, walletDetailsLoading]);
  const setShowValueFirstUpgradeModal = useSetAtom(
    showValueFirstUpgradeModalAtom
  );

  const wabaIntegrationsAtom = useMemo(() => {
    return wabaIntegrationsAtomFamily({
      id: DEFAULT_MEMBER,
    });
  }, []);

  const isEnterpriseAnalyticsRouteAccessible = useMemo(() => {
    if (!enterpriseAnalyticsDetailsLoading) {
      return {
        enterpriseAnalytics: !!enterpriseAnalyticsDetails?.embedLink,
        additionalEnterpriseAnalytics:
          !!enterpriseAnalyticsDetails?.additionalEmbedLink,
      };
    }

    return {
      enterpriseAnalytics: false,
      additionalEnterpriseAnalytics: false,
    };
  }, [enterpriseAnalyticsDetails, enterpriseAnalyticsDetailsLoading]);

  const wabaIntegrationsGetter = useCallback(
    async (paginationParams?: WabaIntegrationPaginationParams) => {
      if (!paginationParams) {
        paginationParams = {
          sortAfter: undefined,
          searchTerm: '',
          hasMore: false,
        };
      }
      const data = await getWabaIntegrations(paginationParams);
      const { integrations, nextSortAfter, hasMore } = data || {};

      return {
        data: integrations,
        paginationData: {
          ...paginationParams,
          sortAfter: nextSortAfter ?? paginationParams?.nextSortAfter,
          searchTerm: '',
          hasMore: hasMore ?? paginationParams.hasMore,
        } as WabaIntegrationPaginationParams,
      };
    },
    []
  );

  const {
    requestData: { data: wabaIntegrationsData },
  } = usePaginatedQuery<WabaIntegration, WabaIntegrationPaginationParams>({
    requestAtom: wabaIntegrationsAtom,
    getter: wabaIntegrationsGetter,
  });

  useMiniListeners();
  useImportExcelSocket();
  useAgentStatusSocket();
  useConversationSocket();

  const syncCompletedExcelFilesStatus = async () => {
    try {
      const { operations } = await getExcelSheetsStatus();
      if (operations.length === 0) return;
      setImportExcelFilesStatus(
        operations.filter(({ status }) => status === OperationStatus.COMPLETE)
      );
      localStorage.removeItem(
        IMPORT_EXCEL_STORAGE_KEYS.IMPORTED_EXCEL_SHEETS_STATUS
      );
    } catch (error) {
      addNotification({
        type: 'error',
        message: 'Error while fetching excel sheets status.',
      });
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem(
        IMPORT_EXCEL_STORAGE_KEYS.IMPORTED_EXCEL_SHEETS_STATUS
      )
    ) {
      setImportExcelFilesStatus(null);
    } else {
      syncCompletedExcelFilesStatus();
    }
  }, []);

  useEffect(() => {
    // to show non VF integrations to move to VF
    const proceedToBookClickedTime = localStorage.getItem(
      'VF_PROCEED_TO_BOOK_CLICKED_TIME'
    );
    const currentTime = new Date().getTime();
    const is360WabaProviderPresent = (wabaIntegrationsData || []).some(
      (wabaIntegration) =>
        wabaIntegration.provider === WabaIntegrationType['360_DIALOG'] &&
        wabaIntegration.status === WabaChannelStatus.ACTIVE
    );

    if (proceedToBookClickedTime && is360WabaProviderPresent) {
      const timeDifferenceInHours = Number(
        (
          (currentTime - Number(proceedToBookClickedTime)) /
          (48 * 60 * 60 * 1000)
        ).toFixed(2)
      );
      if (timeDifferenceInHours > 48) {
        setShowValueFirstUpgradeModal(true);
      }
    } else {
      if (is360WabaProviderPresent) {
        setShowValueFirstUpgradeModal(true);
      } else {
        setShowValueFirstUpgradeModal(false);
        localStorage.removeItem('VF_PROCEED_TO_BOOK_CLICKED_TIME');
      }
    }
  }, [setShowValueFirstUpgradeModal, wabaIntegrationsData]);

  const { isOnline } = useNetworkStatus();
  const socketConnectionState = useAtomValue(socketConnectionAtom);
  const [isInitialDelayDone, setisInitialDelayDone] = useState(false);
  const { id = '' } = useAtomValue(selectedChatAtom) ?? {};
  const location = useLocation();
  const isConversationsPage = useMemo(() => {
    return location.pathname.includes(ROUTES.CONVERSATIONS);
  }, [location]);
  const isAnySocketDisconnected = useMemo(() => {
    return Object.entries(socketConnectionState).some(
      ([name, status]) =>
        !status &&
        (CONVERSATIONS_PAGE_VALID_SOCKET_NAMES.includes(name as SocketName) ||
          name === id)
    );
  }, [socketConnectionState, id]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isConversationsPage) {
      timer = setTimeout(() => {
        setisInitialDelayDone(true);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
      setisInitialDelayDone(false);
    };
  }, [isConversationsPage, id]);

  useEffect(() => {
    if (!!id) setisInitialDelayDone(false);
  }, [id]);

  const showConnectionLostBar = isConversationsPage
    ? (isInitialDelayDone && isAnySocketDisconnected) || !isOnline
    : !isOnline;
  const postLoginRedirect = new URLSearchParams(window.location.search).get(
    'postLoginRedirect'
  );
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete('postLoginRedirect');

  const homeRoutePath = useMemo(() => {
    if (postLoginRedirect) {
      return `${decodeURIComponent(postLoginRedirect)}${
        searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''
      }`;
    }

    return ROUTES.CONVERSATIONS;
  }, [postLoginRedirect, searchParams]);

  const embedHomeRoutePath = useMemo(() => {
    if (postLoginRedirect) {
      return `${decodeURIComponent(postLoginRedirect)}${
        searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''
      }`;
    }

    return `${ROUTES.EMBED.replace('/*', '')}${ROUTES.CONVERSATIONS}`;
  }, [postLoginRedirect, searchParams]);

  const isEmbed = useAtomValue(isEmbedAtom);

  if (isEmbed) {
    return (
      <Routes>
        <Route
          key={ROUTES.EMBED}
          path={ROUTES.EMBED}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <EmbedLazyComponent />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={<Navigate to={embedHomeRoutePath} replace />}
        />
      </Routes>
    );
  }

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Navigate to={homeRoutePath} replace />} />
        {/* <Route
          path={ROUTES.SIGN_IN_WITH_CUSTOM_TOKEN}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <SignInLazyComponent />
            </Suspense>
          }
        /> */}
        <Route
          path={ROUTES.DIALOG_CALLBACK}
          key={ROUTES.DIALOG_CALLBACK}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <Dialog360Callback />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.BILLING_DETAILS}
          path={ROUTES.BILLING_DETAILS}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <BillingDetailsLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.COMPANY_DETAILS}
          path={ROUTES.COMPANY_DETAILS}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <CompanyDetailsLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.EMAIL_VERIFICATION}
          path={ROUTES.EMAIL_VERIFICATION}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <EmailVerificationLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.AUTO_LOGIN}
          path={ROUTES.AUTO_LOGIN}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <AutoLoginLazyComponent />
            </Suspense>
          }
        />
        <Route
          key={ROUTES.NEXT_STEPS}
          path={ROUTES.NEXT_STEPS}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <NextStepsLazyComponent />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.RENEW_PLAN}
          element={
            <ProtectedRoute hasAccess component={RenewPlanLazyComponent} />
          }
        />
        <Route
          path={ROUTES.RENEW_ACCOUNT}
          element={
            <ProtectedRoute
              component={PaymentScreenMiniLazyComponent}
              hasAccess
            />
          }
        />
        <Route
          key={ROUTES.EMBED}
          path={ROUTES.EMBED}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <EmbedLazyComponent />
            </Suspense>
          }
        />
        <Route
          path={ROUTES.CONTACT_SUPPORT}
          key={ROUTES.CONTACT_SUPPORT}
          element={
            <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
              <ContactSupportLazyComponent showContactSupport={true} />
            </Suspense>
          }
        />
        <Route
          key={'/*'}
          path={'/*'}
          element={
            <>
              <ConnectionLostBar
                isVisible={showConnectionLostBar}
                isOnline={isOnline}
                ctaText={isOnline ? 'Refresh' : 'Check now'}
              />
              <Header
                containerStyles={css`
                  transform: ${showConnectionLostBar
                    ? `translateY(40px)`
                    : `translateY(0px)`};
                  transition: transform 500ms ease-in-out;
                `}
              />
              <div
                css={css`
                  height: calc(
                    100% - ${HEADER_HEIGHT}px -
                      ${showConnectionLostBar
                        ? CONNECTION_LOST_STATUS_BAR_HEIGHT
                        : 0}px
                  );
                  transform: ${showConnectionLostBar
                    ? `translateY(40px)`
                    : `translateY(0px)`};
                  transition: transform 500ms ease-in-out;
                `}
              >
                <div
                  css={css`
                    height: 100%;
                    display: flex;
                    flex: 1;
                    position: relative;
                  `}
                >
                  <Sidebar permissions={userPermissions} />
                  <RechargeModal />
                  <UpgradePlanModal />
                  <BotBuilderModal />
                  <PushNotificationPermissionPopup />
                  <NegativeWalletBalanceModal
                    showNegativeWalletBalanceModal={isWalletBalanceNegative}
                  />
                  <div
                    css={css`
                      height: 100%;
                      width: 100%;
                      min-width: 100%;
                      max-width: 100%;
                      flex: 1;
                      padding-left: ${SIDEBAR_WIDTH}px;
                      overflow: hidden;
                    `}
                  >
                    <Routes>
                      {getRoutesV1(
                        userPermissions,
                        isEnterpriseAnalyticsRouteAccessible
                      )}
                    </Routes>
                  </div>
                </div>
              </div>
            </>
          }
        />
      </Routes>
      <CreateOwnAccountModalRenderer />
      <ValueFirstUpgradeModalInformation />
    </Fragment>
  );
}

export function AppUnprotectedRoutes() {
  // const hideDTDetails = shouldHideDTDetails();
  // const renderSignup = () => {
  //   if (hideDTDetails) {
  //     return <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />;
  //   }
  //   return (
  //     <>
  //       <Route
  //         key={ROUTES.SIGNUP}
  //         path={ROUTES.SIGNUP}
  //         element={
  //           <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
  //             <SignupLazyComponent />
  //           </Suspense>
  //         }
  //       />
  //       ,
  //       <Route
  //         key={ROUTES.SIGN_IN}
  //         path={ROUTES.SIGN_IN}
  //         element={
  //           <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
  //             <SignInLazyComponent />
  //           </Suspense>
  //         }
  //       />
  //       ,
  //       <Route
  //         path="*"
  //         element={<Navigate to={ROUTES.SIGNUP.replace('/*', '')} />}
  //       />
  //       ,
  //     </>
  //   );
  // };
  return (
    <Routes>
      <Route
        key={ROUTES.SHOPIFY_SIGNUP}
        path={ROUTES.SHOPIFY_SIGNUP}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <ShopifySignupLazyComponent />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.EMBED_LOGIN}
        path={ROUTES.EMBED_LOGIN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <EmbedAutoLoginLazyComponent />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.LOGIN}
        path={ROUTES.LOGIN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <LoginLazyComponent />
          </Suspense>
        }
      />
      {/* <Route
        path={ROUTES.SIGN_IN_WITH_CUSTOM_TOKEN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <SignInLazyComponent />
          </Suspense>
        }
      /> */}
      <Route
        key={ROUTES.NEXT_STEPS}
        path={ROUTES.NEXT_STEPS}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <NextStepsLazyComponent />
          </Suspense>
        }
      />
      <Route
        path={ROUTES.DIALOG_CALLBACK}
        key={ROUTES.DIALOG_CALLBACK}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <Dialog360Callback />
          </Suspense>
        }
      />
      <Route
        key={ROUTES.AUTO_LOGIN}
        path={ROUTES.AUTO_LOGIN}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <AutoLoginLazyComponent />
          </Suspense>
        }
      />
      <Route
        path={ROUTES.CONTACT_SUPPORT}
        key={ROUTES.CONTACT_SUPPORT}
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <ContactSupportLazyComponent showContactSupport={true} />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<Loader size={32} secondary={LOADING} />}>
            <PostLoginComponent />
          </Suspense>
        }
      />
    </Routes>
  );
}
