let version = '';

export async function getVersion() {
  if (version) {
    return version;
  }

  const packageDetails = await import('../../../package.json');
  version = packageDetails.version;

  // lets send 0, if no version is there, so all additional features are disabled
  return version || '0';
}

export async function getMinorVersion() {
  const version = await getVersion();
  const [major, minor, patch] = version.split('.');
  return `${major}.${minor}.${patch}`;
}
