import { AllUserPermissions, UserStatusData } from './../typings/user';
import { atom } from 'jotai';
import { createRequestAtom } from '../utils/request-atom';
import { Permissions } from '../typings/user';
import { UserDetails } from '../../modules/home/v1/typings';

export const rootScope = Symbol('rootScope');

export const userProfileAtom = atom<UserDetails | undefined>(undefined);

const getPermission = (permission: Permissions, permissions: Set<string>, resources: Map<string, Array<string>>) => {
  if (permission === Permissions.ACCESS_ADS_INSIGHT) {
    return {
      hasAccess: true,
      resources: [],
    };
  }
  return {
    hasAccess: permissions.has(permission),
    resources: resources.get(permission) || [],
  };
};

export const allUserPermissions = atom<AllUserPermissions>((get) => {
  const profile = get(userProfileAtom);

  let permissions = new Set<string>();
  const resourcesMap = new Map<string, Array<string>>();

  if (profile) {
    const roles = profile.access.roles;

    roles.forEach((role) => {
      const policies = role.policies;

      policies.forEach((policy) => {
        let resources = policy.resources.map(({ id }) => id);

        if (resources.length === 0) {
          resources = ['*'];
        }

        permissions.add(policy.permissionId);
        resourcesMap.set(policy.permissionId, resources);
      });
    });
  }

  return {
    canReadConversation: getPermission(Permissions.READ_ALL_CONVERSATIONS, permissions, resourcesMap),
    canWriteConversation: getPermission(Permissions.WRITE_ALL_CONVERSATIONS, permissions, resourcesMap),
    canReadAssignedConversation: getPermission(Permissions.READ_ASSIGNED_CONVERSATIONS, permissions, resourcesMap),
    canReadUnassignedConversation: getPermission(Permissions.READ_UNASSIGNED_CONVERSATIONS, permissions, resourcesMap),
    canWriteUnassignedConversation: getPermission(Permissions.READ_UNASSIGNED_CONVERSATIONS, permissions, resourcesMap),
    canWriteAssignedConversation: getPermission(Permissions.WRITE_ASSIGNED_CONVERSATIONS, permissions, resourcesMap),
    canReadMembers: getPermission(Permissions.READ_TEAM_MEMBERS, permissions, resourcesMap),
    canAddMembers: getPermission(Permissions.ADD_TEAM_MEMBERS, permissions, resourcesMap),
    canRemoveMembers: getPermission(Permissions.DELETE_TEAM_MEMBERS, permissions, resourcesMap),
    canUpdateMembers: getPermission(Permissions.UPDATE_TEAM_MEMBERS, permissions, resourcesMap),
    canReadTemplates: getPermission(Permissions.READ_TEMPLATES, permissions, resourcesMap),
    canDeleteTemplates: getPermission(Permissions.DELETE_TEMPLATE, permissions, resourcesMap),
    canDeleteConversation: getPermission(Permissions.DELETE_CONVERSATIONS, permissions, resourcesMap),
    canReadBots: getPermission(Permissions.READ_BOTS, permissions, resourcesMap),
    canReadTeams: getPermission(Permissions.READ_TEAMS, permissions, resourcesMap),
    canAddIntegration: getPermission(Permissions.ADD_INTEGRATION, permissions, resourcesMap),
    canConnectIntegration: getPermission(Permissions.CONNECT_INTEGRATION, permissions, resourcesMap),
    canDisconnectIntegration: getPermission(Permissions.DISCONNECT_INTEGRATION, permissions, resourcesMap),
    canReadIntegration: getPermission(Permissions.GET_INTEGRATIONS, permissions, resourcesMap),
    canReadCustomerPhone: getPermission(Permissions.READ_CUSTOMER_PHONE, permissions, resourcesMap),
    canAccessAdsInsights: getPermission(Permissions.ACCESS_ADS_INSIGHT, permissions, resourcesMap),
    canGetCustomers: getPermission(Permissions.GET_CUSTOMERS, permissions, resourcesMap),
    canGetAnalyticsWidgets: getPermission(Permissions.GET_ANALYTICS_WIDGETS, permissions, resourcesMap),
    canWriteAnalyticsWidgets: getPermission(Permissions.WRITE_ANALYTICS_WIDGETS, permissions, resourcesMap),
    canDeleteAnalyticsWidgets: getPermission(Permissions.DELETE_ANALYTICS_WIDGETS, permissions, resourcesMap),
    canManageWallet: getPermission(Permissions.MANAGE_WALLET, permissions, resourcesMap),
    canManageChannels: getPermission(Permissions.MANAGE_CHANNELS, permissions, resourcesMap),
    canManageChannelAccess: getPermission(Permissions.MANAGE_CHANNEL_ACCESS, permissions, resourcesMap),
    canManageCreatedChannelAccess: getPermission(Permissions.MANAGE_CREATED_CHANNEL_ACCESS, permissions, resourcesMap),
    channelAccessLevelFullAccess: getPermission(
      Permissions.CHANNEL_ACCESS_LEVEL_FULL_ACCESS,
      permissions,
      resourcesMap
    ),
    channelAccessLevelSendOnlyAccess: getPermission(
      Permissions.CHANNEL_ACCESS_LEVEL_SEND_ONLY_ACCESS,
      permissions,
      resourcesMap
    ),
    channelAccessLevelNoAccess: getPermission(Permissions.CHANNEL_ACCESS_LEVEL_NO_ACCESS, permissions, resourcesMap),
    canManagePrivacyBotAccess: getPermission(Permissions.PRIVACY_BOT, permissions, resourcesMap),
    canExportGroupMembers: getPermission(Permissions.EXPORT_GROUP_MEMBERS, permissions, resourcesMap),
    canManageRoles: getPermission(Permissions.MANAGE_ROLES, permissions, resourcesMap),
    canGetBillingDetails: getPermission(Permissions.GET_BILLING_DETAILS, permissions, resourcesMap),
    canUpdateBillingDetails: getPermission(Permissions.UPDATE_BILLING_DETAILS, permissions, resourcesMap),
    canGetWalletInvoices: getPermission(Permissions.GET_WALLET_INVOICES, permissions, resourcesMap),
    canDownloadWalletInvoices: getPermission(Permissions.DOWNLOAD_WALLET_INVOICES, permissions, resourcesMap),
    canGetSubscriptionInvoices: getPermission(Permissions.GET_SUBSCRIPTION_INVOICES, permissions, resourcesMap),
    canDownloadSubscriptionInvoices: getPermission(
      Permissions.DOWNLOAD_SUBSCRIPTION_INVOICES,
      permissions,
      resourcesMap
    ),
    canGetWabaUsageInvoices: getPermission(Permissions.GET_WABA_USAGE_SUMMARY, permissions, resourcesMap),
    canDownloadWabaUsageInvoices: getPermission(Permissions.DOWNLOAD_WABA_USAGE_SUMMARY, permissions, resourcesMap),
    canGetReports: getPermission(Permissions.GET_REPORTS, permissions, resourcesMap),
    canReadTeamAssignedConversation: getPermission(
      Permissions.READ_TEAM_ASSIGNED_CONVERSATIONS,
      permissions,
      resourcesMap
    ),
    canChangeChatAssignee: getPermission(Permissions.CHANGE_CHAT_ASSIGNEE, permissions, resourcesMap),
    canManageCustomFields: getPermission(Permissions.MANAGE_CUSTOM_FIELDS, permissions, resourcesMap),
    canReadChatCustomFields: getPermission(Permissions.CAN_READ_CHAT_CUSTOM_FIELDS, permissions, resourcesMap),
    canEditChatCustomFields: getPermission(Permissions.CAN_EDIT_CHAT_CUSTOM_FIELDS, permissions, resourcesMap),
    canCreateTemplates: getPermission(Permissions.CREATE_TEMPLATES, permissions, resourcesMap),
    canAccessZohoSync: getPermission(Permissions.ZOHO_INTEGRATION_MANAGER, permissions, resourcesMap),
  };
});

export const userRoleAtom = atom((get) => get(userProfileAtom)?.access?.role);

export const userStatusAtom = createRequestAtom<UserStatusData>();
